import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
//Components
import SEO from "../components/SEO/SEO"
import Layout from "../components/Layout/Layout"
import PageHero from "../components/PageHero/PageHero"
import GridSystem from "../components/GridSystem"
import { motion, useScroll, useTransform } from "framer-motion"
import { Row, Col, Container } from "react-bootstrap"
import { func } from "prop-types"

const Wrapper = styled.div`
  max-width: 1180px;
  margin: 0 auto;
  padding: 20px;
`

const ContentWrapper = styled.div`
  display: block;

  @media (min-width: 992px) {
    display: flex;
  }
`

const WorkSingleTemplate = ({ data }) => {
  //parallax
  let { scrollYProgress } = useScroll()
  let y = useTransform(scrollYProgress, [0, 1], ["0%", "50%"])

  return (
    <Layout>
      <SEO title={data.wpWork.title} />
      <div className="aspect-ratio">
        <iframe className="vimeo-video" src={data.wpWork.vimeoVideoUrl} />
      </div>
      <motion.div style={{ y }}>
        <Container>
          <h4
            className="work-page-title"
            dangerouslySetInnerHTML={{ __html: data.wpWork.title }}
          />

          <Row>
            <Col lg={8}>
              <div
                className="work-content"
                dangerouslySetInnerHTML={{ __html: data.wpWork.content }}
              />
            </Col>
            <Col lg={4}>
              <section className="infoSection">
                <h2 className="section-title">Client:</h2>
                <h4 dangerouslySetInnerHTML={{ __html: data.wpWork.client }} />
              </section>
              <section className="infoSection">
                <h2 className="section-title">Services:</h2>
                <div className="serviceInfo">
                  {data.wpWork.serviceInfo.map((service, i) => (
                    <div key={i} className={"service-name"}>
                      <div dangerouslySetInnerHTML={{ __html: service }} />
                    </div>
                  ))}
                </div>
              </section>
            </Col>
          </Row>

          <section className="infoSection">
            <h2 className="section-title">Credits:</h2>
            <div className="creditInfo">
              <GridSystem colCount={2} md={6}>
                {data.wpWork.creditInfo.map((credit, i) => (
                  <div key={i} className={"credit-person"}>
                    <strong
                      dangerouslySetInnerHTML={{ __html: credit.title + ": " }}
                    />
                    <span dangerouslySetInnerHTML={{ __html: credit.name }} />
                  </div>
                ))}
              </GridSystem>
            </div>
          </section>

          <section className="infoSection">
            {data.wpWork.extraSpots[0] && (
              <h2 className="section-title">Spots:</h2>
            )}
          </section>
        </Container>
      </motion.div>
      <div className="extraspots">
        <GridSystem colCount={1} md={12}>
          {data.wpWork.extraSpots.map((video, i) => (
            <div key={i} className={"video-link"}>
              <iframe
                className="extra-spots"
                src={video}
                title="Vimeo video player"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                frameBorder="0"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                allowFullScreen
              ></iframe>
            </div>
          ))}
        </GridSystem>
      </div>
    </Layout>
  )
}

export default WorkSingleTemplate

export const query = graphql`
  query ($id: String!) {
    wpWork(id: { eq: $id }) {
      slug
      id
      title
      content
      client
      creditInfo {
        title
        name
      }
      serviceInfo
      extraSpots
      vimeoVideoUrl
      featuredImage {
        node {
          id
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1920, placeholder: TRACED_SVG)
            }
          }
        }
      }
    }
  }
`
